import React from "react";
import { Button } from "react-bootstrap";

import Style from "./StyleGuide.module.scss";

const StyleGuide = () => {
  return (
    <div className="container">
      <div className="pt-5 pb-3">
        <h1>Heding 1</h1>
        <h2>Heding 2</h2>
        <h3>Heding 3</h3>
        <h4>Heding 4</h4>
        <h5>Heding 5</h5>
        <h6>Heding 6</h6>
      </div>
      <hr />
      <div className="pt-3 pb-5">
        <h3>Buttons</h3>
        <div className="mt-4">
          <Button variant="primary">Button #1</Button>
          <Button variant="secondary" className="mx-2">
            Button #2
          </Button>
          <Button variant="primary" className="btn-primary-border">
            Button #3
          </Button>
        </div>
      </div>
      <hr />
      <div className="pt-3 pb-5">
        <h3>Form Control</h3>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="first_name"
                placeholder="First Name*"
              />
              <label htmlFor="first_name">First Name</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group error">
              <input
                type="email"
                className="form-control"
                id="email_address"
                placeholder="Email Address"
              />
              <label htmlFor="email_address">Email Address*</label>
              <small>Invalid Email Address</small>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <textarea
                className="form-control textarea"
                id="user_comment"
                placeholder="Comment"
              ></textarea>
              <label htmlFor="user_comment">Comment</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleGuide;
