import React from "react";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import Assets from "../Assets";

const CommonLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Pentad Securities</title>
        <meta name="description" content="Financial product distribution and brokerage services at affordable prices and customized support" />
        <meta property="og:title" content="Pentad Securities" />
        <meta property="og:description" content="Financial product distribution and brokerage services at affordable prices and customized support" />
        <meta property="og:image" content={Assets.siteLogo} />
      </Helmet>
      <main className="site_main noscroll">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default CommonLayout;
