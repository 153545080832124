import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  downloads: null,
};

export const getDownloadData = createAsyncThunk(
  "downloads",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/downloads");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getDownloadSlice = createSlice({
  name: "downloads",
  initialState,
  reducers: {},
  extraReducers: {
    [getDownloadData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getDownloadData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.downloads = action.payload?.data?.data?.details?.list;
    },
    [getDownloadData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getDownloadSlice.reducer;
