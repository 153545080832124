import Style from "./Loader.module.scss";

export const Loader = () => {
  return (
    <div className={Style.site_loader}>
      <div className={`loader ${Style.site_loader_progress}`}>
        <div
          className={Style.site_loader_progress_full_bar}
          style={{ width: "70%" }}
        ></div>
      </div>
    </div>
  );
};
