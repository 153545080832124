import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  escalationMatrixStatus: "idle",
  escalation: null,
};

export const getEscalationMatrix = createAsyncThunk(
  "escalationMatrix",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get(
        "/contact-us/escalation-matrix"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getEscalationMatrixSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {},
  extraReducers: {
    [getEscalationMatrix.pending]: (state) => {
      state.escalationMatrixStatus = "loading";
      state.message = null;
    },
    [getEscalationMatrix.fulfilled]: (state, action) => {
      state.escalationMatrixStatus = "succeeded";
      state.escalation = action?.payload?.data?.data?.details;
    },
    [getEscalationMatrix.rejected]: (state) => {
      state.escalationMatrixStatus = "failed";
    },
  },
});

export default getEscalationMatrixSlice.reducer;
