import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  footer_menu: null,
  footer_commondetails: null,
};

export const getFooterMenuData = createAsyncThunk(
  "footer/menu",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/footer-menu");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFooterCommonDetailsData = createAsyncThunk(
  "footer/commondetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/footer-common-details");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getFooterDataSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {},
  extraReducers: {
    [getFooterMenuData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getFooterMenuData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.footer_menu = action.payload?.data;
    },
    [getFooterMenuData.rejected]: (state) => {
      state.status = "failed";
    },

    [getFooterCommonDetailsData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getFooterCommonDetailsData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.footer_commondetails = action.payload?.data;
    },
    [getFooterCommonDetailsData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getFooterDataSlice.reducer;
