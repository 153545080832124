import { useState } from 'react';
import Style from './ScrollArrow.module.scss';

const ScrollArrow = () =>{

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 200){
      setShowScroll(true)
    } else if (showScroll && window.scrollY <= 200){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
        <button className={showScroll ? `${Style.scroll_top_btn} ${Style.scroll_top_btn_show}` : Style.scroll_top_btn} onClick={scrollTop} ></button>
  );
}

export default ScrollArrow;