import React from "react";
import { Link } from "react-router-dom";

export const TOC = () => {
  return (
    <div className="container">
      <div className="resposive-table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Pages</th>
              <th scope="col">Link</th>
              <th scope="col">Status</th>
              <th scope="col">Allocated Resource</th>
            </tr>

          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Home</td>
              <td>
                <Link target={"_blank"} to={"/"}>
                  Home
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Naveen, Rosemol</td>
            </tr>

            <tr>
              <th scope="row">2</th>
              <td>About</td>
              <td>
                <Link target={"_blank"} to={"/about-us"}>
                  About
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Naveen</td>
            </tr>
            
            <tr>
              <th scope="row">3</th>
              <td>Blog</td>
              <td>
                <Link target={"_blank"} to={"/blog"}>
                  Blog
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">4</th>
              <td>Attention Investors</td>
              <td>
                <Link target={"_blank"} to={"/attention-investors"}>
                  Attention Investors
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">5</th>
              <td>Contact</td>
              <td>
                <Link target={"_blank"} to={"/contact-us"}>
                  Contact
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">6</th>
              <td>Faq</td>
              <td>
                <Link target={"_blank"} to={"/faq"}>
                  Faq
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">7</th>
              <td>Download</td>
              <td>
                <Link target={"_blank"} to={"/downloads"}>
                  Download
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">8</th>
              <td>Terms And Conditions</td>
              <td>
                <Link target={"_blank"} to={"/terms-conditions"}>
                  Terms And Conditions
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">9</th>
              <td>AML Policy</td>
              <td>
                <Link target={"_blank"} to={"/aml-policy"}>
                  AML Policy
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">10</th>
              <td>General Terms & Conditions</td>
              <td>
                <Link target={"_blank"} to={"/general-terms-and-conditions"}>
                  General Terms & Conditions
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">11</th>
              <td>Privacy Policy</td>
              <td>
                <Link target={"_blank"} to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">12</th>
              <td>Policies & Procedures </td>
              <td>
                <Link target={"_blank"} to={"/policies-and-procedure"}>
                  Policies & Procedures{" "}
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">13</th>
              <td>General Disclaimer</td>
              <td>
                <Link target={"_blank"} to={"/general-disclaimer"}>
                  General Disclaimer
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">14</th>
              <td>Registered Advisors</td>
              <td>
                <Link target={"_blank"} to={"/investing-with-registered-advisors"}>
                  Registered Advisors
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">15</th>
              <td>Equity</td>
              <td>
                <Link target={"_blank"} to={"/equity"}>
                  Equity
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Naveen</td>
            </tr>

            <tr>
              <th scope="row">16</th>
              <td>Mutual Funds</td>
              <td>
                <Link target={"_blank"} to={"/mutual-funds"}>
                Mutual Funds
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">17</th>
              <td>Bonds</td>
              <td>
                <Link target={"_blank"} to={"/bonds"}>
                Bonds
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">18</th>
              <td>Exchange Trade</td>
              <td>
                <Link target={"_blank"} to={"/exchange-traded-funds"}>
                Exchange Trade
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">19</th>
              <td>Unlisted Shares</td>
              <td>
                <Link target={"_blank"} to={"/unlisted-shares"}>
                Unlisted Shares
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>

            <tr>
              <th scope="row">20</th>
              <td>Platforms</td>
              <td>
                <Link target={"_blank"} to={"/platforms"}>
                Platforms
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Naveen</td>
            </tr>
            <tr>
              <th scope="row">21</th>
              <td>Future & Option</td>
              <td>
                <Link target={"_blank"} to={"/future-option"}>
                Future & Option
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>
            <tr>
              <th scope="row">22</th>
              <td>Global Investing</td>
              <td>
                <Link target={"_blank"} to={"/global-investing"}>
                Global Investing
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>
            <tr>
              <th scope="row">23</th>
              <td>Fixed Deposit</td>
              <td>
                <Link target={"_blank"} to={"/fixed-deposit"}>
                Fixed Deposit
                </Link>
              </td>
              <td>
                <p className="text-success font-weight-bold text-left">
                  completed
                </p>
              </td>
              <td>Rosemol</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
