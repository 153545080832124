import React from "react";
import Style from "./FooterMobileNav.module.scss";

import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";


import { useProducts } from "../../../../logic/useProducts";
import { currentSelectedProduct } from "../../../../store/slices/ProductsSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const FooterMobileNav = ({ footer_menu }) => {
  const { getProductItem } = useProducts();
  const parse = require("html-react-parser");
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <div className={Style.footer_mobile}>
      <Accordion className="footer_navigation_bar">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Products</Accordion.Header>
          <Accordion.Body>
            <ul>
              {footer_menu?.products_menu?.map((value, index) => {
                return (
                  <li
                    key={index}
                    className={
                      location.pathname.substring(1) === value?.slug
                        ? `active`
                        : ""
                    }
                  >
                    <Link
                      to={`/${value?.slug}`}
                      onClick={() => {
                        getProductItem(value?.slug);
                        dispatch(currentSelectedProduct(value?.slug));
                      }}
                    >
                      {parse(value?.title)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Platforms</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <a href={"/"} target={"_blank"}>
                  Pen
                </a>
              </li>
              <li>
                <a href={"/"} target={"_blank"}>
                  Book
                </a>
              </li>
              <li>
                <a
                  href={"https://wealthelite.in/client-login"}
                  target={"_blank"}
                >
                  Mutual Fund
                </a>
              </li>
              <li>
                <a href={"/"} target={"_blank"}>
                  Global Investing
                </a>
              </li>
              <li>
                <a
                  href="https://evoting.cdslindia.com/Evoting/EvotingLogin"
                  target={"_blank"}
                >
                  Evoting
                </a>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Legal</Accordion.Header>
          <Accordion.Body>
            <ul>
              {footer_menu?.legal_menu?.map((value, index) => {
                return (
                  <li
                    key={index}
                    className={
                      location.pathname.substring(1) === value?.slug
                        ? `active`
                        : ""
                    }
                  >
                    <Link to={`/${value?.slug}`}>{parse(value?.title)}</Link>
                  </li>
                );
              })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Quick Links</Accordion.Header>
          <Accordion.Body>
            <ul>
              {footer_menu?.quick_links?.map((value, index) => {
                return (
                  <li
                    key={index}
                    className={
                      location.pathname.substring(1) === value?.slug
                        ? `active`
                        : ""
                    }
                  >
                    <Link to={`/${value?.slug}`}>{parse(value?.title)}</Link>
                  </li>
                );
              })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Useful Links</Accordion.Header>
          <Accordion.Body>
            <ul>
              {footer_menu?.useful_links?.map((value, index) => {
                return (
                  <li key={index}>
                    <a href={value?.url} target={value?.target}>
                      {parse(value?.title)}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default FooterMobileNav;
