import * as React from "react";
import { Routes, Route } from "react-router-dom";

import "../styles/common.scss";

import StyleGuidePage from "../pages/StyleGuidePage";
import { TOC } from "../pages/TOC";
/* Style Guide */
import { Suspense } from "react";
import { Loader } from "./Loader";
import { ErrorPage } from "../pages/ErrorPage";
import Menu from "./Layouts/Menu";

const HomePage = React.lazy(() => import("../pages/HomePage"));
const ContactPage = React.lazy(() => import("../pages/ContactPage"));
const FaqPage = React.lazy(() => import("../pages/FaqPage"));
const AboutUsPage = React.lazy(() => import("../pages/AboutUsPage"));
const DownloadPage = React.lazy(() => import("../pages/DownloadPage"));
const AttentionInvestorsPage = React.lazy(() =>
  import("../pages/AttentionInvestorsPage")
);
const BlogPage = React.lazy(() => import("../pages/BlogPage"));
const BlogDetailPage = React.lazy(() => import("../pages/BlogDetailPage"));
const ScrollToTop = React.lazy(() => import("./ScrollToTop"));
const AMLPolicyPage = React.lazy(() => import("../pages/AMLPolicyPage"));
const GeneralDisclaimerPage = React.lazy(() =>
  import("../pages/GeneralDisclaimerPage")
);
const GeneralTermsPage = React.lazy(() => import("../pages/GeneralTermsPage"));
const ServiceTermsPage = React.lazy(() => import("../pages/ServiceTermsPage"));
const PoliciesPage = React.lazy(() => import("../pages/PoliciesPage"));
const PrivacyPolicyPage = React.lazy(() =>
  import("../pages/PrivacyPolicyPage")
);
const EquityPage = React.lazy(() =>
  import("../pages/SingleProductsPage/EquityPage")
);
const MutualFundsPage = React.lazy(() =>
  import("../pages/SingleProductsPage/MutualFundsPage")
);
const RegisteredAdvisorsPage = React.lazy(() =>
  import("../pages/SingleProductsPage/RegisteredAdvisorsPage")
);
const BondPage = React.lazy(() =>
  import("../pages/SingleProductsPage/BondPage")
);
const UnlistedSharesPage = React.lazy(() =>
  import("../pages/SingleProductsPage/UnlistedSharesPage")
);
const ExchangeTradePage = React.lazy(() =>
  import("../pages/SingleProductsPage/ExchangeTradePage")
);
const GlobalInvestingPage = React.lazy(() =>
  import("../pages/SingleProductsPage/GlobalInvestingPage")
);
const PlatformPage = React.lazy(() => import("../pages/PlatformPage"));
const FixedDepositPage = React.lazy(() =>
  import("../pages/SingleProductsPage/FixedDepositPage")
);
const FutureAndOptionPage = React.lazy(() =>
  import("../pages/SingleProductsPage/FutureAndOptionPage")
);
const EscalationMatrixPage = React.lazy(() =>
  import("../pages/EscalationMatrixPage")
);
const KycPolicyPage = React.lazy(() => import("../pages/KycPolicyPage"));
const NriInvestmentPage = React.lazy(() =>
  import("../pages/NriInvestmentPage")
);
const UpdationProvisionPage = React.lazy(() =>
  import("../pages/UpdationProvisionPage")
);
const ComingSoonPage = React.lazy(() => import("../pages/ComingSoonPage"));
const InvestorGrievancesPage = React.lazy(() =>
  import("../pages/InvestorGrievancesPage")
);

const App = () => {
  return (
    <ScrollToTop>
      <Menu />
      <Suspense fallback={""}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blogs/:id" element={<BlogDetailPage />} />
          <Route path="/downloads" element={<DownloadPage />} />

          <Route
            path="/attention-investors"
            element={<AttentionInvestorsPage />}
          />
          <Route path="/aml-policy" element={<AMLPolicyPage />} />
          <Route
            path="/general-disclaimer"
            element={<GeneralDisclaimerPage />}
          />
          <Route
            path="/general-terms-and-conditions"
            element={<GeneralTermsPage />}
          />
          <Route path="/terms-conditions" element={<ServiceTermsPage />} />
          <Route path="/policies-and-procedure" element={<PoliciesPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          <Route path="/equity" element={<EquityPage />} />
          <Route path="/mutual-funds" element={<MutualFundsPage />} />
          <Route path="/bonds" element={<BondPage />} />
          <Route
            path="/exchange-traded-funds"
            element={<ExchangeTradePage />}
          />
          <Route path="/global-investing" element={<GlobalInvestingPage />} />
          <Route path="/fixed-deposit" element={<FixedDepositPage />} />
          <Route path="/future-option" element={<FutureAndOptionPage />} />
          <Route path="/unlisted-shares" element={<UnlistedSharesPage />} />
          <Route
            path="/investing-with-registered-advisors"
            element={<RegisteredAdvisorsPage />}
          />
          <Route path="/platforms" element={<PlatformPage />} />
          <Route
            path="/investor-grievances"
            element={<InvestorGrievancesPage />}
          />
          <Route path="/regulator-kyc-policies" element={<KycPolicyPage />} />
          <Route path="/nri-investment" element={<NriInvestmentPage />} />
          <Route
            path="/updation-provision"
            element={<UpdationProvisionPage />}
          />
          <Route path="/escalation-matrix" element={<EscalationMatrixPage />} />

          <Route path="/style-guide" element={<StyleGuidePage />} />
          <Route path="/toc" element={<TOC />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
