import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  recentBlogs: null,
};

export const getRecentBlogsData = createAsyncThunk(
  "recentBlogs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/home/recent-blogs");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getRecentBlogsSlice = createSlice({
  name: "recentBlogs",
  initialState,
  reducers: {},
  extraReducers: {
    [getRecentBlogsData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getRecentBlogsData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.recentBlogs = action.payload?.data?.data?.details?.blog_list;
    },
    [getRecentBlogsData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getRecentBlogsSlice.reducer;
