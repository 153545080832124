import React from "react";

import { Link } from "react-router-dom";
import Style from './Error.module.scss';
import Assets from "../../components/Layouts/Assets";
import CommonLayout from "../../components/Layouts/CommonLayout";

export const ErrorPage = () => {
  return (
    <CommonLayout>
      <section className={Style.error_page}>
          <div className='container'>
            <figure>
                <img src={Assets.error_img} alt="Page Not Found" />
            </figure>
            <h1>Page Not Found</h1>
            <p>We can’t find the page you’re looking for</p>
            <Link to={'/'} className={`btn btn-primary ${Style.back_to_btn}`}>Back to Home</Link>
          </div>
      </section>
    </CommonLayout>
  );
};
