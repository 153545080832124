import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Assets from "../../Assets";
import Style from "../Menu.module.scss";
import { useGetHoverData } from "../../../../logic/useGetHoverData";
import { useSelector } from "react-redux";
import { DummyData } from "../../../DummyData/index";
import { currentSelectedProduct } from "../../../../store/slices/ProductsSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export const DesktopNav = ({ menu }) => {
  const location = useLocation();
  const { fetchOnMouseHover } = useGetHoverData();

  const [megaMenu, setmegaMenu] = useState(true);

  const { status } = useSelector((state) => state.header);
  const dispatch = useDispatch();

  const { HeaderMenu } = DummyData();

  const parse = require("html-react-parser");

  const length = menu?.[1]?.sub_menu?.length;
  const len = length % 2;
  const splitValue = length / 2 + len;

  const hideMegaMenu = (e) => {
    setmegaMenu(false);
  };

  return (
    <nav className={`${Style.site_header_navbar_nav}`}>
      {status == "succeeded" ? (
        <ul>
          {menu?.map((value, index) => {
            return (
              <li
                key={index}
                className={`${value?.sub_menu?.[0] ? Style.parent : ""} ${
                  location.pathname.substring(1) === value?.slug
                    ? Style.active
                    : ""
                }`}
                onMouseEnter={()=>{setmegaMenu(true)}}
                onTouchStart={()=>{setmegaMenu(true)}}
              >
                <Link
                  onMouseEnter={() => {
                    fetchOnMouseHover(`/${value?.slug}`);
                  }}
                  to={`${value?.slug}`}
                >
                  {parse(value?.title)}
                </Link>
                <ul
                  className={`${
                    value?.class !== ""
                      ? `${Style.mega_menu} ${Style.mega_menu_show}`
                      : ""
                  } ${megaMenu
                    ? ""
                    : Style.mega_menu_hide }`
                  
                  }
                >
                  <div
                    className={
                      value?.class !== "" ? Style.mega_menu_split : "false"
                    }
                  >
                    {value.sub_menu
                      ?.slice(0, splitValue)
                      .map((item, submenuIndex) => {
                        return (
                          <li
                            key={submenuIndex}
                            onClick={hideMegaMenu}
                            className={
                              location.pathname.substring(1) === item?.slug
                                ? Style.active
                                : ""
                            }
                          >
                            <Link
                              to={`/${item?.slug}`}
                              onClick={() => {
                                dispatch(currentSelectedProduct(item?.slug));
                              }}
                            >
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_icon
                                    : "false"
                                }
                              >
                                <img src={item?.icon?.url} />
                              </span>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_text
                                    : "false"
                                }
                              >
                                {parse(item?.title)}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </div>
                  <div
                    className={
                      value?.class !== "" ? Style.mega_menu_split : "false"
                    }
                  >
                    {value.sub_menu
                      ?.slice(splitValue, length)
                      .map((item, indexValue) => {
                        return (
                          <li
                            key={indexValue}
                            onClick={hideMegaMenu}
                            className={
                              location.pathname.substring(1) === item?.slug
                                ? Style.active
                                : ""
                            }
                          >
                            <Link
                              to={`/${item?.slug}`}
                              onClick={() => {
                                dispatch(currentSelectedProduct(item?.slug));
                              }}
                            >
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_icon
                                    : ""
                                }
                              >
                                <img src={item?.icon?.url} />
                              </span>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_text
                                    : ""
                                }
                              >
                                {parse(item?.title)}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </div>
                </ul>
              </li>
            );
          })}

          <li className={`${Style.parent} ${Style.parent_nav}`}>
            <Link to={"#"}>Open An Account</Link>
            <ul className={Style.submenu}>
              <li>
                <a
                  href="https://ekyc.pentadsecurities.com/client/"
                  target={"_blank"}
                >
                  <span>Trading & Demat Account</span>
                </a>
              </li>
              <li>
                <a href="#" target={"_blank"}>
                  <span>Global</span>
                </a>
              </li>
            </ul>
          </li>
          <li className={Style.header_sign_in}>
            <Button
              variant="primary"
              className={`btn-primary-border ${Style.nav_btn}`}
            >
              <span>Login</span>
            </Button>

            <ul
              className={`${Style.header_sign_in_menu} ${Style.header_sign_in_menu_show}`}
            >
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.pen_logo} alt="PEN" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>Pen</span>
                </a>
              </li>
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.book_logo} alt="Book" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>Book</span>
                </a>
              </li>
              <li>
                <a href={"https://wealthelite.in/client-login"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.mutual_fund_logo} alt="Mutual Fund" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    Mutual Fund
                  </span>
                </a>
              </li>
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img
                      src={Assets.global_investing_logo}
                      alt="Global Investing"
                    />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    Global Investing
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={"https://evoting.cdslindia.com/Evoting/EvotingLogin"}
                  target="_blank"
                >
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.evoting_icon} alt="Evoting" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    Evoting
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      ) : (
        <ul  style={{pointerEvents: "none"}}>
          {HeaderMenu?.map((value, index) => {
            return (
              <li
                key={index}
                className={value?.sub_menu?.[0] ? Style.parent : "false"}
              >
                <Link to={`/${value?.slug}`}>{value?.title}</Link>
                <ul
                  className={
                    value?.class !== ""
                      ? `${Style.mega_menu} ${Style.mega_menu_show}`
                      : ""
                  }
                >
                  <div
                    className={
                      value?.class !== "" ? Style.mega_menu_split : "false"
                    }
                  >
                    {value.sub_menu
                      ?.slice(0, splitValue)
                      .map((item, submenuIndex) => {
                        return (
                          <li key={submenuIndex}>
                            <Link to={`/${item?.slug}`}>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_icon
                                    : "false"
                                }
                              >
                                <img src={item?.icon} />
                              </span>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_text
                                    : "false"
                                }
                              >
                                {item?.title}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </div>
                  <div
                    className={
                      value?.class !== "" ? Style.mega_menu_split : "false"
                    }
                  >
                    {value.sub_menu
                      ?.slice(splitValue, length)
                      .map((item, submenuIndex) => {
                        return (
                          <li key={submenuIndex}>
                            <Link to={`/${item?.slug}`}>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_icon
                                    : ""
                                }
                              >
                                <img src={item?.icon?.url} />
                              </span>
                              <span
                                className={
                                  value?.class !== ""
                                    ? Style.mega_menu_text
                                    : ""
                                }
                              >
                                {item?.title}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </div>
                </ul>
              </li>
            );
          })}

          <li className={`${Style.parent} ${Style.parent_nav}`}>
            <Link to={"#"}>Open An Account</Link>
            <ul className={Style.submenu}>
              <li>
                <a
                  href={"https://ekyc.pentadsecurities.com/client/"}
                  target="_blank"
                >
                  <span>Trading & Demat Account</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <span>Global</span>
                </a>
              </li>
            </ul>
          </li>
          <li className={Style.header_sign_in}>
            <Button
              variant="primary"
              className={`btn-primary-border ${Style.nav_btn}`}
            >
              <span>Login</span>
            </Button>

            <ul
              className={`${Style.header_sign_in_menu} ${Style.header_sign_in_menu_show}`}
            >
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.pen_logo} alt="PEN" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>PEN</span>
                </a>
              </li>
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.book_logo} alt="BOOK" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>BOOK</span>
                </a>
              </li>
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.mutual_fund_logo} alt="MUTUAL FUND" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    MUTUAL FUND
                  </span>
                </a>
              </li>
              <li>
                <a href={"/"} target="_blank">
                  <span className={Style.header_sign_in_menu_icon}>
                    <img
                      src={Assets.global_investing_logo}
                      alt="GLOBAL INVESTING"
                    />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    GLOBAL INVESTING
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={"https://ekyc.pentadsecurities.com/client/"}
                  target="_blank"
                >
                  <span className={Style.header_sign_in_menu_icon}>
                    <img src={Assets.evoting_icon} alt="evoting" />
                  </span>
                  <span className={Style.header_sign_in_menu_text}>
                    Evoting
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      )}
    </nav>
  );
};
