import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAxiosInstance from "../../apis";

const initialState = {
  error: null,
  status: "idle",
  menu: null,
  marquee: null,
};

export const getMenuData = createAsyncThunk(
  "header/menu",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/header-menu");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMarqueeData = createAsyncThunk(
  "header/marquee",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAxiosInstance.get("/attention");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getHomeHeaderSlice = createSlice({
  name: "header",
  initialState,
  reducers: {},
  extraReducers: {
    [getMenuData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getMenuData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.menu = action.payload?.data;
    },
    [getMenuData.rejected]: (state) => {
      state.status = "failed";
    },

    [getMarqueeData.pending]: (state) => {
      state.status = "loading";
      state.message = null;
    },
    [getMarqueeData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.marquee = action.payload?.data?.attention?.attention_title;
    },
    [getMarqueeData.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getHomeHeaderSlice.reducer;
